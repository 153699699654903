import React, { useMemo, useEffect } from 'react'

import {
  Drawer,
  DrawerContent,
  DrawerOverlay,
  useDisclosure,
  useMediaQuery,
} from '@chakra-ui/react'
import { Box, Flex } from '@pnld/components-web'

import { useSidebar } from '@/contexts/AppContext'

import MobileNav from './parts/MobileNav'
import SidebarContent from './parts/SibebarContent'
import { ISidebarProps } from './types'

function Sidebar({ linkItems, children }: ISidebarProps) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { isMini, toggleSidebarState } = useSidebar()

  const [isMobile] = useMediaQuery('(max-width: 575px)')
  const sidebarWidth = useMemo(() => (isMini ? 12 : 48), [isMini])

  useEffect(() => {
    if (isMini) {
      toggleSidebarState()
    }
  }, [isMobile])

  return (
    <Box minHeight="100vh" bg="white">
      <SidebarContent
        display={{ base: 'none', md: 'flex' }}
        onClose={() => onClose}
        toggleSidebar={toggleSidebarState}
        sidebarWidth={sidebarWidth}
        linkItems={linkItems}
      />
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
      >
        <DrawerOverlay />
        <DrawerContent>
          <SidebarContent
            onClose={onClose}
            toggleSidebar={toggleSidebarState}
            sidebarWidth={sidebarWidth}
            linkItems={linkItems}
          />
        </DrawerContent>
      </Drawer>
      <MobileNav
        display={{ base: 'flex', md: 'none' }}
        onOpen={onOpen}
        sidebarWidth={sidebarWidth}
      />
      <Flex
        flexDirection="column"
        minHeight="100vh"
        flexGrow={1}
        ml={{ base: 0, md: sidebarWidth }}
        p={{ base: 4, md: 12 }}
        pt={{ base: 3, md: 6 }}
      >
        {children}
      </Flex>
    </Box>
  )
}

export default Sidebar
