import React, { useRef } from 'react'

import { FormikProps, FormikValues } from 'formik'

// import { useToast } from '@chakra-ui/react'
import { IOptionProps, useToast } from '@pnld/components-web'

const tableData = [
  {
    id: 1,
    filename: 'Nome do arquivo',
    username: 'Nome do usuário',
    sendDate: '2021-08-12',
  },
  {
    id: 2,
    filename: 'Nome do arquivo 2',
    username: 'Nome do usuário 2',
    sendDate: '2021-08-13',
  },
  {
    id: 3,
    filename: 'Nome do arquivo 3',
    username: 'Nome do usuário 3',
    sendDate: '2021-08-14',
  },
  {
    id: 4,
    filename: 'Nome do arquivo 4',
    username: 'Nome do usuário 4',
    sendDate: '2021-08-15',
  },
  {
    id: 5,
    filename: 'Nome do arquivo 5',
    username: 'Nome do usuário 5',
    sendDate: '2021-08-16',
  },
]

export const useSendingsController = ({ onClose }: { onClose: () => void }) => {
  const toast = useToast()
  const formikRef = useRef<FormikProps<FormikValues>>(null)

  const handleSearchChange = (search: string) => {
    // TODO: Implement search
  }

  const handleUserChange = (user: IOptionProps) => {
    // TODO: Implement user filter
  }

  const handleDateChange = (date: string) => {
    // TODO: Implement date filter
  }

  const sendData = () => {
    if (formikRef.current) {
      formikRef.current.handleSubmit()
    }
  }

  const handleSubmit = (values: FormikValues) => {
    // TODO: Implement submit
    toast({
      title: 'Dados enviados com sucesso',
      status: 'success',
      duration: 3000,
      isClosable: true,
    })
    onClose()
  }

  return {
    formikRef,
    handleSearchChange,
    handleUserChange,
    handleDateChange,
    sendData,
    handleSubmit,
    tableData,
    toast,
  }
}
