import React from 'react'
import { Outlet } from 'react-router-dom'

import { Stack, Flex } from '@chakra-ui/react'

import { sidebarProps, useBreadcrumbProps } from '@/data/template'

import Breadcrumbs from './parts/Breadcrumbs'
import Sidebar from './parts/Sidebar'

function TemplatePage() {
  const breadcrumbProps = useBreadcrumbProps()

  return (
    <Sidebar {...sidebarProps}>
      <Stack flexGrow={1} spacing="4">
        <Flex>
          <Breadcrumbs {...breadcrumbProps} />
        </Flex>
        <Outlet />
      </Stack>
    </Sidebar>
  )
}

export default TemplatePage
