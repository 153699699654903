import React, { useMemo } from 'react'

import {
  Box,
  Stack,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react'
import {
  DataGrid,
  TColumnDef,
  IconButton,
  Typography,
  Button,
  Icon,
  Form,
  Select,
  IOptionProps,
  useModal,
} from '@pnld/components-web'

import { useSendingsController } from './controller'
import SendDataModal from './SendDataModal'
import { ISendings } from './types'

const ActionButtons = (row: ISendings) => {
  const { id } = row

  const handleDelete = () => {
    // TODO: Implement edit action
  }

  const handleView = () => {
    // TODO: Implement view action
  }

  return (
    <Flex>
      <IconButton
        aria-label="Visualizar"
        iconName="eye"
        variant="tertiary"
        onClick={handleView}
      />
      <IconButton
        aria-label="Deletar"
        iconName="trash"
        variant="tertiary"
        onClick={handleDelete}
      />
    </Flex>
  )
}

const Sendings = () => {
  const { isOpen, onClose, onOpen } = useModal()
  const { tableData, handleSearchChange, handleUserChange, handleDateChange } =
    useSendingsController({ onClose })

  const columns = useMemo<Array<TColumnDef<ISendings>>>(
    () => [
      {
        accessorKey: 'filename',
        header: 'First Name',
      },
      {
        accessorKey: 'username',
        header: 'Last Name',
      },
      {
        accessorFn: row => new Date(row.sendDate).toLocaleDateString(),
        header: 'Data de envio',
      },
      {
        cell: ({ row }) => ActionButtons(row.original),
        header: 'Actions',
      },
    ],
    []
  )

  return (
    <Stack
      flexGrow={1}
      flex={1}
      p={5}
      boxShadow="md"
      borderRadius="base"
      gap={4}
    >
      <Typography variant="h-xs" color="brand.primary.dark_1">
        Histórico de envios
      </Typography>
      <Flex justify="space-between">
        <Box>
          <InputGroup>
            <Input
              placeholder="Buscar por"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleSearchChange(e.target.value)
              }
            />
            <InputRightElement>
              <Icon name="search" size="2xs" />
            </InputRightElement>
          </InputGroup>
        </Box>
        <Button
          label="Enviar dados"
          variant="secondary"
          iconName="upload"
          onClick={onOpen}
        />
      </Flex>
      <Form onSubmit={() => {}}>
        <Flex w="full" gap={4}>
          <Flex align="center">
            <Typography
              variant="b-sm"
              color="brand.primary.dark_1"
              whiteSpace="nowrap"
            >
              Filtrar por
            </Typography>
          </Flex>
          <Select
            name="user"
            placeholder="Usuário"
            options={[
              { label: 'Usuário 1', value: '1' },
              { label: 'Usuário 2', value: '2' },
              { label: 'Usuário 3', value: '3' },
              { label: 'Usuário 4', value: '4' },
              { label: 'Usuário 5', value: '5' },
            ]}
            onChange={e => handleUserChange(e as IOptionProps)}
          />
          <Input
            placeholder="Data"
            type="date"
            onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleDateChange(e.target.value)
            }
          />
        </Flex>
      </Form>
      <Box overflowX="auto" flexGrow={1}>
        <DataGrid columns={columns} data={tableData} />
      </Box>
      <SendDataModal isOpen={isOpen} onClose={onClose} />
    </Stack>
  )
}

export default Sendings
