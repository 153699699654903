import React from 'react'
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  Outlet,
  useSearchParams,
} from 'react-router-dom'

import TemplatePage from '@/common/Templates/TemplatePage'
import { AppContextProvider } from '@/contexts/AppContext'
import Login from '@/pages/Login'
import LoginAccessDenied from '@/pages/Login/AccessDenied'
import Panel from '@/pages/Panel'
import Sendings from '@/pages/Panel/Sendings'
import { stringToBoolean } from '@/utils/string'

const RedirectGovBrLogin = () => {
  const [searchParams] = useSearchParams()

  const code = searchParams.get('code')
  const state = searchParams.get('state')

  if (code && state) {
    return <Navigate to={`/login/?code=${code}&state=${state}`} replace />
  }

  return <Outlet />
}

class App extends React.Component {
  render() {
    const isDevLogin = stringToBoolean(process.env.REACT_APP_DEV_LOGIN)

    return (
      <AppContextProvider>
        <BrowserRouter>
          <Routes>
            <Route element={<RedirectGovBrLogin />}>
              {/* <Route path="/" element={<TemplatePage />}></Route> */}
            </Route>
            <Route path="login">
              <Route index element={<Login />} />
              <Route path="acesso-negado" element={<LoginAccessDenied />} />
            </Route>
            <Route path="/" element={<TemplatePage />}>
              <Route path="painel" element={<Panel />} />
              <Route path="envios" element={<Sendings />} />
              <Route path="predicao" element={<h1>Predicao</h1>} />
              <Route index element={<Navigate to="/painel" />} />
            </Route>
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </BrowserRouter>
      </AppContextProvider>
    )
  }
}

export default App
